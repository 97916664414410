<template>
  <b-form-group label="Kategóriák">
    <ul class="list-group dense">
      <draggable :list="form.Menuk" :options="dragOptions">
        <li
          v-for="menu in visibleMenuk"
          :key="menu.Id"
          class="list-group-item p-0"
        >
          <k-collapsible-container headerClass="hidden-container">
            <template v-slot:title>
              <div
                class="d-flex justify-content-between align-items-center w-100 p-2"
              >
                <div class="d-flex align-items-center flex-fill pr-2">
                  <k-inline-text-edit :value.sync="menu.Nev" :text="menu.Nev">
                  </k-inline-text-edit>
                </div>
                <div
                  class="li-tile pointer hidden-item"
                  @click="KategoriaTorles(menu)"
                  v-b-tooltip="'Törlés'"
                >
                  <i class="fa fa-trash"></i>
                </div>
                <div
                  class="li-tile"
                  :class="{ 'hidden-item': !menu.Kiemelt }"
                  @click.stop="menu.Kiemelt = !menu.Kiemelt"
                  v-b-tooltip="'Kiemelt'"
                >
                  <i
                    class="fa-star text-primary"
                    :class="menu.Kiemelt ? 'fa' : 'far'"
                  ></i>
                </div>
              </div>
            </template>
            <ul class="list-group list-group-flush">
              <li
                v-for="kategoria in GetVisibleAlkategoriak(menu)"
                :key="kategoria.Id"
                class="list-group-item d-flex justify-content-between align-items-center pl-4 hidden-container"
              >
                <div class="d-flex align-items-center w-100">
                  <k-file-select
                    accept="image/*"
                    :block="false"
                    class="mr-2"
                    @inputBase64="kategoria.Kep = $event[0]"
                  >
                    <img
                      class="li-img border-radius-6"
                      :src="
                        kategoria.Kep
                          ? kategoria.Kep + '?h=30'
                          : $placeholderImage
                      "
                    />
                  </k-file-select>
                  <k-inline-text-edit
                    :value.sync="kategoria.Nev"
                    :text="kategoria.Nev"
                  >
                  </k-inline-text-edit>
                </div>

                <div
                  class="li-tile mr-2 pointer hidden-item"
                  @click="AlKategoriaTorles(menu, kategoria)"
                  v-b-tooltip="'Törlés'"
                >
                  <i class="fa fa-trash"></i>
                </div>
              </li>
              <li
                class="list-group-item d-flex justify-content-start align-items-center pointer pl-4"
                @click="UjAlKategoria(menu)"
              >
                <div class="li-tile grey mr-2">
                  <i class="fa fa-plus"></i>
                </div>
                <span>
                  Új alkategória
                </span>
              </li>
            </ul>
          </k-collapsible-container>
        </li>
      </draggable>
      <li
        class="list-group-item d-flex justify-content-start align-items-center pointer"
        @click="UjKategoria"
      >
        <div class="li-tile grey mr-2">
          <i class="fa fa-plus"></i>
        </div>
        <span>
          Új kategória
        </span>
      </li>
    </ul>
  </b-form-group>
</template>

<script>

  /* eslint vue/no-mutating-props: 0 */

export default {
  name: 'pcf-kategoriak',
  data() {
    return {
      ct: -1,
    };
  },
  mounted() {},
  created() {},
  methods: {
    KategoriaTorles(menu) {
      if (menu.Id > 0) {
        let prev = this.form.Menuk.find(f => f.Id == menu.Id);
        this.$set(prev, 'ToroltFl', true);
      } else {
        this.form.Menuk = this.form.Menuk.filter(f => f.Id != menu.Id);
      }
    },
    UjKategoria() {
      let id = this.ct--;
      this.form.Menuk.push({
        Id: id,
        Nev: `Menu${id}`,
        Kep: null,
        Kategoria: [],
        ToroltFl: false,
      });
    },
    AlKategoriaTorles(menu, kategoria) {
      let m = this.form.Menuk.find(f => f.Id == menu.Id);
      if (kategoria.Id > 0) {
        let prev = m.Kategoria.find(f => f.Id == kategoria.Id);
        this.$set(prev, 'ToroltFl', true);
      } else {
        m.Kategoria = m.Kategoria.filter(f => f.Id != kategoria.Id);
      }
    },
    UjAlKategoria(menu) {
      let id = this.ct--;
      let m = this.form.Menuk.find(f => f.Id == menu.Id);
      m.Kategoria.push({
        Id: id,
        Nev: `Kategoria${id}`,
        Kep: null,
        ToroltFl: false,
      });
    },
    GetVisibleAlkategoriak(menu) {
      return menu.Kategoria.filter(f => !f.ToroltFl);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        // ghostClass: 'ghost',
        scrollSensitivity: 100,
        ignore: 'img',
      };
    },
    visibleMenuk() {
      return this.form.Menuk.filter(f => !f.ToroltFl);
    },
  },
  watch: {},
  props: {
    form: {
      requied: true,
    },
  },
};
</script>
